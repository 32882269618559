<template>
  <v-form @submit.prevent="localOnSubmit" ref="formRef">
    <v-row dense>
      <v-col>
        <v-text-field dense label="Procedimento" v-model="currentItem.nome" outlined :rules="requiredField" />
      </v-col>
      <v-col cols="3">
        <v-select hide-details :items="statusOptions" v-model="currentItem.evo_completa" label="Texto Evolução Completa" outlined dense  item-value="value" item-text="label"/>
      </v-col>
      <v-col cols="3">
        <v-select hide-details :items="statusOptions" v-model="currentItem.especial" label="Especial" outlined dense  item-value="value" item-text="label"/>
      </v-col>
      <v-col cols="3">
        <v-select hide-details :items="statusOptions" v-model="currentItem.avaliacao" label="Avaliação" outlined dense  item-value="value" item-text="label"/>
      </v-col>
      <v-col cols="4">
        <v-select hide-details :items="statusOptions" v-model="currentItem.assinatura_obrigatoria" label="Assinatura Obrigatória do Paciente" outlined dense  item-value="value" item-text="label"/>
      </v-col>
      <v-col cols="3">
        <vuetify-money dense label="Custo estimado" v-model="currentItem.custo" outlined :rules="requiredField" />
      </v-col>
    </v-row>
    <v-row dense>
      <v-btn-toggle v-model="currentItem.ativo" mandatory class="white--text">
        <v-btn :color="!currentItem.ativo ? 'error' : ''">Inativo</v-btn>
        <v-btn :color="currentItem.ativo ? 'green' : ''">Ativo</v-btn>
      </v-btn-toggle>
    </v-row>
    <v-row class="justify-end mt-0" dense>
      <v-col class="text-end">
        <ConfirmButton :loading="loading" color="success">Salvar</ConfirmButton>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import UtilsFunc from '../../../../../service/utilsFunc';
import ConfirmButton from '../../../../ConfirmButton.vue';

export default {
  name: "BaseFormProcedure",
  props: {
    currentItem: Object,
    onSubmit: Function,
    loading: Boolean
  },
  data() {
    return UtilsFunc.withCRUDUtils({
      requiredField: [e => (e ? true : 'Obrigatório')],
      statusOptions: [
        { label: "SIM", value: true }, // Alterado para valor booleano verdadeiro
        { label: "NÃO", value: false }], // Alterado para valor booleano falso
    })
  },
  components: { ConfirmButton },
  methods: {
    localOnSubmit() {
      const validation = this.$refs.formRef.validate()
      if (!validation)
        return
      const fields = {
        ...this.currentItem
      }
      this.onSubmit(fields)
    },
  },
  mounted() {
    this.currentItem.ativo = Number(this.currentItem.ativo)
  }
}
</script>
